import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/Callapi';
import { deleteR, get, getWithData, post } from '../services/request';
import { apiUrl, url_add_transcription, url_edit_transcription, url_get_transcriptions,url_detail_transcriptions, url_download_transcription_frappe} from '../services/api';
import { get_liste_transcriptions_done, add_transcription_done,edit_transcription_done,Detail_transcription_done, delete_transcription_done, download_transcription_frappe_done } from '../actions/TranscriptonAction';


function* get_liste_transcriptions_saga(action) {
	yield call(
		callApi,
		apiUrl + url_get_transcriptions+'?statut='+action.payload,
		get,
        null,
        get_liste_transcriptions_done,
	);
}

export function* watch_get_liste_transcriptions_saga() {
    yield takeEvery('LISTE_TRANSCRIPTIONS', get_liste_transcriptions_saga);
}

function* add_transcription_saga(action) {
	yield call(
		callApi,
		apiUrl + url_add_transcription,
		post,
        action.payload,
        add_transcription_done,
	);
}
export function* watch_add_transcription_saga() {
    yield takeEvery('ADD_TRANSCRIPTION', add_transcription_saga);
}


function* edit_transcription_saga(action) {
	yield call(
		callApi,
		apiUrl + url_edit_transcription,
		getWithData,
        action.payload,
        edit_transcription_done,
	);
}
export function* watch_edit_transcription_saga() {
    yield takeEvery('EDIT_TRANSCRIPTION', edit_transcription_saga);
}


function* Detail_transcription_saga(action) {
	yield call(
		callApi,
		apiUrl + url_detail_transcriptions + action.payload+'/',
		get,
        action.payload,
        Detail_transcription_done,
	);
}
export function* watch_Detail_transcription_saga() {
    yield takeEvery('DETAIL_TRANSCRIPTION', Detail_transcription_saga);
}


function* Delete_transcription_saga(action) {
	yield call(
		callApi,
		apiUrl + url_get_transcriptions + action.payload.id+'/?statut='+action.payload.statut,
		deleteR,
        null,
        delete_transcription_done,
	);
}
export function* watch_Delete_transcription_saga() {
    yield takeEvery('DELETE_TRANSCRIPTION', Delete_transcription_saga);
}


function* download_transcription_frappe_saga(action) {
	yield call(
		callApi,
		apiUrl + url_download_transcription_frappe,
		post,
        action.payload,
        download_transcription_frappe_done,
	);
}
export function* watch_download_transcription_frappe_saga() {
    yield takeEvery('DOWNLOAD_TRANSCRIPTION', download_transcription_frappe_saga);
}