import { combineReducers } from 'redux';
import transcription from './transcription';
import frappe from './frappe';
import user from './user';
import admin from './admin';

export default combineReducers({
    transcription : transcription,
    frappe : frappe,
    user : user,
    admin : admin,
});