import React from 'react';
import { Spinner } from 'react-bootstrap';
import './commun.css';

export default function SpinnerPage() {
    return (
        <div className='SpinnerPage'>
            <Spinner animation="border" role="status" variant="success">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
}
