export const initialState = {
    liste_frappes : [],
    liste_relectures : [],
    frappe : {},
    user_occuped : {},
    relecture : {},
    frappe_relache : {},
    frappe_finalise : {},
    frappe_edited : {},
    relecture_edited : {},
    frappe_changed : {},
    transcription_added : {},
    transcription_edited : {},
    transcription_deleted : {},
    transcription_validated : {},
}

const frappe = (state = initialState, action) => {
    switch (action.type) {
        case 'LISTE_FRAPPES_DONE':
            return {
                ...state,
                liste_frappes: action.payload,
            }
        case 'LISTE_RELECTURES_DONE':
            return {
                ...state,
                liste_relectures: action.payload,
            }
        case 'DETAIL_FRAPPE_DONE':
            return {
                ...state,
                frappe: action.payload,
            }
        case 'DETAIL_RELECTURE_DONE':
            return {
                ...state,
                relecture: action.payload,
            }
        case 'EDIT_FRAPPE_DONE':
            return {
                ...state,
                frappe_edited: action.payload,
            }
        case 'EDIT_RELECTURE_DONE':
            return {
                ...state,
                relecture_edited: action.payload,
            }
        case 'ADD_TRANSCRIPTION_FRAPPE_DONE':
            return {
                ...state,
                transcription_added: action.payload,
            }
        case 'EDIT_TRANSCRIPTION_FRAPPE_DONE':
            return {
                ...state,
                transcription_edited: action.payload,
            }
        case 'DELETE_TRANSCRIPTION_FRAPPE_DONE':
            return {
                ...state,
                transcription_deleted: action.payload,
            }
        case 'VALIDE_TRANSCRIPTION_FRAPPE_DONE':
            return {
                ...state,
                transcription_validated: action.payload,
            }
        case 'RELACHE_FRAPPE_DONE':
            return {
                ...state,
                frappe_relache: action.payload,
            }
        case 'FINALISATION_FRAPPE_DONE':
            return {
                ...state,
                frappe_finalise: action.payload,
            }
        case 'CHECK_CHANGEMENT_DONE':
            return {
                ...state,
                frappe_changed: action.payload,
            }
        case 'CHECK_USER_HAS_FRAPPE_DONE':
            return {
                ...state,
                user_occuped: action.payload,
            }
        default:
            return state;
    }
}
export default frappe