export const initialState = {
    Liste_filters : [],
    Liste_related_filters : [],
    Liste_client : [],
    Liste_etablissement : [],
    data : []
}

const admin = (state = {}, action) => {
    switch (action.type) {
        case 'LISTE_CLIENT_DONE':
            return {
                ...state,
                Liste_client: action.payload,
            }
        case 'LISTE_ETABLISSEMENT_DONE':
            return {
                ...state,
                Liste_etablissement: action.payload,
            }
        case 'LISTE_DATA_DONE':
            return {
                ...state,
                data: action.payload,
            }
        case 'LISTE_FILTERS_DONE':
            return {
                ...state,
                Liste_filters: action.payload,
            }
        case 'LISTE_RELATED_FILTERS_DONE':
            return {
                ...state,
                Liste_related_filters: action.payload,
            }
        default:
            return state;
    }
}
export default admin