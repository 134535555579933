import { all } from 'redux-saga/effects';
import { 
    watch_add_transcription_saga, 
    watch_Delete_transcription_saga, 
    watch_Detail_transcription_saga, 
    watch_download_transcription_frappe_saga, 
    watch_edit_transcription_saga, 
    watch_get_liste_transcriptions_saga 
} from './transcription';
import { 
    watch_add_transcription_frappe_saga,
    watch_check_changement_saga,
    watch_check_user_has_frappe_saga,
    watch_delete_transcription_frappe_saga,
    watch_Detail_frappe_saga, 
    watch_Detail_relecture_saga, 
    watch_edit_frappe_saga, 
    watch_edit_relecture_saga, 
    watch_edit_transcription_frappe_saga, 
    watch_finalisation_frappe_saga, 
    watch_get_liste_frappes_saga, 
    watch_get_liste_relectures_saga, 
    watch_relache_frappe_saga,
    watch_valider_transcription_frappe_saga
} from './frappe';
import { 
    watch_change_password_saga, 
    watch_confirm_password_saga, 
    watch_get_user_action_saga, 
    watch_login_saga, 
    watch_refresh_token_saga
} from './user';
import { 
    watch_get_client_saga, 
    watch_get_data_saga, 
    watch_get_etablissement_saga,
    watch_get_list_filters_saga,
    watch_get_list_related_filters_saga, 
} from './admin';


export default function* rootSaga() {
    yield all([
        //Login_ResetPassword_Related
        watch_get_user_action_saga(),
        watch_login_saga(),
        watch_change_password_saga(),
        watch_confirm_password_saga(),
        watch_refresh_token_saga(),
        //Client_Related
        watch_get_liste_transcriptions_saga(),
        watch_add_transcription_saga(),
        watch_edit_transcription_saga(),
        watch_Detail_transcription_saga(),
        watch_Delete_transcription_saga(),
        watch_download_transcription_frappe_saga(),
        //User_Related
        watch_get_liste_frappes_saga(),
        watch_get_liste_relectures_saga(),
        watch_Detail_frappe_saga(),
        watch_add_transcription_frappe_saga(),
        watch_edit_transcription_frappe_saga(),
        watch_delete_transcription_frappe_saga(),
        watch_relache_frappe_saga(),
        watch_edit_frappe_saga(),
        watch_edit_relecture_saga(),
        watch_check_changement_saga(),
        watch_Detail_relecture_saga(),
        watch_finalisation_frappe_saga(),
        watch_check_user_has_frappe_saga(),
        watch_valider_transcription_frappe_saga(),
        //Admin_Related
        watch_get_client_saga(),
        watch_get_etablissement_saga(),
        watch_get_list_filters_saga(),
        watch_get_list_related_filters_saga(),
        watch_get_data_saga(),
    ]);
}
  