import {lazy , Suspense} from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import routes from './services/routes' ;
import urls from './services/urls' ;
import Cookies from 'universal-cookie';
import SpinnerPage from "./components/commun/SpinnerPage";

const cookies = new Cookies();
const Error404 = lazy( () => import('./components/Error404') )

function App() {
  return (
    <>
      <Suspense fallback={<SpinnerPage />}>        
          <Switch>
            {
              routes.map((route,index)=>{             
                if(route.login && !cookies.get('access_token')){
                  return <Redirect key={`route-${index}`} to={urls.login} />;
                } else {
                  return <Route  key={`route-${index}`} path={route.path} component={route.component}/>;
                }                         
              },this)
            }
            <Route exact path="/">
                {cookies.get('access_token') ? <Redirect to={`/${cookies.get('role')+'/home'}`} /> : <Redirect to="/login" />}
            </Route>
            <Route component={Error404}/>
          </Switch>       
      </Suspense> 
    </>
  );
}

export default App;
