import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/Callapi';
import { deleteR, get, patch, post } from '../services/request';
import { apiUrl , url_get_liste_frappe,url_detail_frappe, url_add_transcription_frappe, url_delete_transcription_frappe, url_edit_transcription_frappe, url_get_liste_relecture, url_relache_frappe, url_edit_frappe, url_check_changement_frappe, url_finalisation_frappe, url_edit_relecture, url_check_user_has_frappe, url_valider_transcription_frappe} from '../services/api';
import { get_liste_frappes_done, Detail_frappe_done, get_liste_relectures_done, add_transcription_frappe_done, delete_transcription_frappe_done, edit_transcription_frappe_done, relache_frappe_done, edit_frappe_done, check_changement_done, Detail_relecture_done, finalisation_frappe_done, edit_relecture_done, check_user_has_frappe_done, valider_transcription_frappe_done } from '../actions/FrappeAction';


function* get_liste_frappes_saga(action) {
	yield call(
		callApi,
		apiUrl + url_get_liste_frappe+'?statut='+action.payload+'&phase=frappe',
		get,
        null,
        get_liste_frappes_done,
	);
}

export function* watch_get_liste_frappes_saga() {
    yield takeEvery('LISTE_FRAPPES', get_liste_frappes_saga);
}

function* get_liste_relectures_saga(action) {
	yield call(
		callApi,
		apiUrl + url_get_liste_relecture+'?statut='+action.payload+'&phase=relecture',
		get,
        null,
        get_liste_relectures_done,
	);
}

export function* watch_get_liste_relectures_saga() {
    yield takeEvery('LISTE_RELECTURES', get_liste_relectures_saga);
}


function* Detail_frappe_saga(action) {
	yield call(
		callApi,
		apiUrl + url_detail_frappe + action.payload + '&phase=frappe',
		get,
        action.payload,
        Detail_frappe_done,
	);
}

export function* watch_Detail_frappe_saga() {
    yield takeEvery('DETAIL_FRAPPE', Detail_frappe_saga);
}


function* Detail_relecture_saga(action) {
	yield call(
		callApi,
		apiUrl + url_detail_frappe + action.payload+ '&phase=relecture',
		get,
        action.payload,
        Detail_relecture_done,
	);
}

export function* watch_Detail_relecture_saga() {
    yield takeEvery('DETAIL_RELECTURE', Detail_relecture_saga);
}


function* add_transcription_frappe_saga(action) {
	yield call(
		callApi,
		apiUrl + url_add_transcription_frappe,
		post,
        action.payload,
        add_transcription_frappe_done,
	);
}

export function* watch_add_transcription_frappe_saga() {
    yield takeEvery('ADD_TRANSCRIPTION_FRAPPE', add_transcription_frappe_saga);
}


function* edit_transcription_frappe_saga(action) {
	yield call(
		callApi,
		apiUrl + url_edit_transcription_frappe + action.payload.id+'/',
		patch,
        action.payload.transcription,
        edit_transcription_frappe_done,
	);
}

export function* watch_edit_transcription_frappe_saga() {
    yield takeEvery('EDIT_TRANSCRIPTION_FRAPPE', edit_transcription_frappe_saga);
}


function* delete_transcription_frappe_saga(action) {
	yield call(
		callApi,
		apiUrl + url_delete_transcription_frappe + action.payload+'/',
		deleteR,
        action.payload,
        delete_transcription_frappe_done,
	);
}

export function* watch_delete_transcription_frappe_saga() {
    yield takeEvery('DELETE_TRANSCRIPTION_FRAPPE', delete_transcription_frappe_saga);
}


function* valider_transcription_frappe_saga(action) {
	yield call(
		callApi,
		apiUrl + url_valider_transcription_frappe + action.payload.id+'/',
		patch,
        action.payload.transcription,
        valider_transcription_frappe_done,
	);
}

export function* watch_valider_transcription_frappe_saga() {
    yield takeEvery('VALIDE_TRANSCRIPTION_FRAPPE', valider_transcription_frappe_saga);
}


function* relache_frappe_saga(action) {
	yield call(
		callApi,
		apiUrl + url_relache_frappe,
		post,
        action.payload,
        relache_frappe_done,
	);
}

export function* watch_relache_frappe_saga() {
    yield takeEvery('RELACHE_FRAPPE', relache_frappe_saga);
}

function* edit_frappe_saga(action) {
	yield call(
		callApi,
		apiUrl + url_edit_frappe + action.payload + '&phase=frappe',
		patch,
        action.payload,
        edit_frappe_done,
	);
}

export function* watch_edit_frappe_saga() {
    yield takeEvery('EDIT_FRAPPE', edit_frappe_saga);
}

function* edit_relecture_saga(action) {
	yield call(
		callApi,
		apiUrl + url_edit_relecture + action.payload +'&phase=relecture',
		patch,
        action.payload,
        edit_relecture_done,
	);
}

export function* watch_edit_relecture_saga() {
    yield takeEvery('EDIT_RELECTURE', edit_relecture_saga);
}


function* finalisation_frappe_saga(action) {
	yield call(
		callApi,
		apiUrl + url_finalisation_frappe,
		post,
        action.payload,
        finalisation_frappe_done,
	);
}

export function* watch_finalisation_frappe_saga() {
    yield takeEvery('FINALISATION_FRAPPE', finalisation_frappe_saga);
}


function* check_changement_saga(action) {
	yield call(
		callApi,
		apiUrl + url_check_changement_frappe,
		post,
        action.payload,
        check_changement_done,
	);
}

export function* watch_check_changement_saga() {
    yield takeEvery('CHECK_CHANGEMENT', check_changement_saga);
}


function* check_user_has_frappe_saga(action) {
	yield call(
		callApi,
		apiUrl + url_check_user_has_frappe,
		post,
        action.payload,
        check_user_has_frappe_done,
	);
}

export function* watch_check_user_has_frappe_saga() {
    yield takeEvery('CHECK_USER_HAS_FRAPPE', check_user_has_frappe_saga);
}


