import {lazy} from "react";
import urls from '../services/urls' ;

const Login = lazy( () => import('../components/Login/index') )
const UserHome = lazy( () => import('../components/User/index') )
const DetailFrappe = lazy( () => import('../components/User/DetailFrappe') )
const DetailRelecture = lazy( () => import('../components/User/DetailRelecture') )
const ClientHome = lazy( () => import('../components/Client/index') )
const AddTranscription = lazy( () => import('../components/Client/DemandeTranscription') )
const DetailTranscription = lazy( () => import('../components/Client/DetailTranscription') )
const ModifierTranscription = lazy( () => import('../components/Client/EditDemandeTranscription') )
const AdminHome = lazy( () => import('../components/Admin/index') )

const routes = [
    {
        component: Login,
        path: urls.login,
    },
    {
        component: UserHome,
        path: urls.utilisateur,
        role : 'utlisateur',
        login: true,
    },
    {
        component: DetailFrappe,
        path: urls.utilisateurDetailFrappe+"/:id?",
        role : 'utlisateur',
        login: true,
    },
    {
        component: DetailRelecture,
        path: urls.utilisateurDetailRelecture+"/:id?",
        role : 'utlisateur',
        login: true,
    },
    {
        component: ClientHome,
        path: urls.client,
        role : 'client',
        login: true,
    },
    {
        component: AddTranscription,
        path: urls.clientAddTranscription,
        role : 'client',
        login: true,
    },
    {
        component: DetailTranscription,
        path: urls.clientDetailTranscription+"/:id?",
        role : 'client',
        login: true,
    },
    {
        component: ModifierTranscription,
        path: urls.clientModifierTranscription+"/:id?",
        role : 'client',
        login: true,
    },
    {
        component: AdminHome,
        path: urls.admin,
        role : 'admin',
        login: true,
    },
]

export default routes