export function get_client_action(payload) {
    return {
        type: 'LISTE_CLIENT',
        payload,
    };
}
export function get_client_action_done(request) {
    return {
        type: 'LISTE_CLIENT_DONE',
        payload: request,
    };
}


export function get_etablissemnet_action(payload) {
    return {
        type: 'LISTE_ETABLISSEMENT',
        payload,
    };
}
export function get_etablissemnet_action_done(request) {
    return {
        type: 'LISTE_ETABLISSEMENT_DONE',
        payload: request,
    };
}


export function get_list_filters_action(payload) {
    return {
        type: 'LISTE_FILTERS',
        payload,
    };
}
export function get_list_filters_action_done(request) {
    return {
        type: 'LISTE_FILTERS_DONE',
        payload: request,
    };
}


export function get_list_related_filters_action(payload) {
    return {
        type: 'LISTE_RELATED_FILTERS',
        payload,
    };
}
export function get_list_related_filters_action_done(request) {
    return {
        type: 'LISTE_RELATED_FILTERS_DONE',
        payload: request,
    };
}


export function get_data_action(payload) {
    return {
        type: 'LISTE_DATA',
        payload,
    };
}
export function get_data_action_done(request) {
    return {
        type: 'LISTE_DATA_DONE',
        payload: request,
    };
}
