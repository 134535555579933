import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/Callapi';
import { get, post } from '../services/request';
import { apiUrl, url_get_list_filters, url_get_list_related_filters, url_get_reports} from '../services/api';
import { get_client_action_done, get_data_action_done, get_etablissemnet_action_done, get_list_filters_action_done, get_list_related_filters_action_done } from '../actions/AdminAction';

function* get_client_saga() {
	yield call(
		callApi,
		apiUrl,
		get,
        null,
        get_client_action_done,
	);
}
export function* watch_get_client_saga() {
    yield takeEvery('LISTE_CLIENT', get_client_saga);
}


function* get_etablissement_saga() {
	yield call(
		callApi,
		apiUrl,
		get,
        null,
        get_etablissemnet_action_done,
	);
}
export function* watch_get_etablissement_saga() {
    yield takeEvery('LISTE_ETABLISSEMENT', get_etablissement_saga);
}


function* get_list_filters_saga() {
	yield call(
		callApi,
		apiUrl + url_get_list_filters,
		get,
        null,
        get_list_filters_action_done,
	);
}
export function* watch_get_list_filters_saga() {
    yield takeEvery('LISTE_FILTERS', get_list_filters_saga);
}


function* get_list_related_filters_saga(action) {
	yield call(
		callApi,
		apiUrl + url_get_list_related_filters,
		post,
        action.payload,
        get_list_related_filters_action_done,
	);
}
export function* watch_get_list_related_filters_saga() {
    yield takeEvery('LISTE_RELATED_FILTERS', get_list_related_filters_saga);
}


function* get_data_saga(action) {
	yield call(
		callApi,
		apiUrl + url_get_reports,
		post,
        action.payload,
        get_data_action_done,
	);
}
export function* watch_get_data_saga() {
    yield takeEvery('LISTE_DATA', get_data_saga);
}

