

export function get_liste_frappes(payload) {
    return {
        type: 'LISTE_FRAPPES',
        payload,
    };
}
export function get_liste_frappes_done(request) {
    return {
        type: 'LISTE_FRAPPES_DONE',
        payload: request,
    };
}


export function get_liste_relectures(payload) {
    return {
        type: 'LISTE_RELECTURES',
        payload,
    };
}
export function get_liste_relectures_done(request) {
    return {
        type: 'LISTE_RELECTURES_DONE',
        payload: request,
    };
}


export function Detail_frappe(payload) {
    return {
        type: 'DETAIL_FRAPPE',
        payload,
    };
}
export function Detail_frappe_done(request) {
    return {
        type: 'DETAIL_FRAPPE_DONE',
        payload: request,
    };
}

export function Detail_relecture(payload) {
    return {
        type: 'DETAIL_RELECTURE',
        payload,
    };
}
export function Detail_relecture_done(request) {
    return {
        type: 'DETAIL_RELECTURE_DONE',
        payload: request,
    };
}

export function edit_relecture(payload) {
    return {
        type: 'EDIT_RELECTURE',
        payload,
    };
}
export function edit_relecture_done(request) {
    return {
        type: 'EDIT_RELECTURE_DONE',
        payload: request,
    };
}

export function edit_frappe(payload) {
    return {
        type: 'EDIT_FRAPPE',
        payload,
    };
}
export function edit_frappe_done(request) {
    return {
        type: 'EDIT_FRAPPE_DONE',
        payload: request,
    };
}


export function add_transcription_frappe(payload) {
    return {
        type: 'ADD_TRANSCRIPTION_FRAPPE',
        payload,
    };
}
export function add_transcription_frappe_done(request) {
    return {
        type: 'ADD_TRANSCRIPTION_FRAPPE_DONE',
        payload: request,
    };
}

export function edit_transcription_frappe(payload) {
    return {
        type: 'EDIT_TRANSCRIPTION_FRAPPE',
        payload,
    };
}
export function edit_transcription_frappe_done(request) {
    return {
        type: 'EDIT_TRANSCRIPTION_FRAPPE_DONE',
        payload: request,
    };
}

export function delete_transcription_frappe(payload) {
    return {
        type: 'DELETE_TRANSCRIPTION_FRAPPE',
        payload,
    };
}
export function delete_transcription_frappe_done(request) {
    return {
        type: 'DELETE_TRANSCRIPTION_FRAPPE_DONE',
        payload: request,
    };
}

export function valider_transcription_frappe(payload) {
    return {
        type: 'VALIDE_TRANSCRIPTION_FRAPPE',
        payload,
    };
}
export function valider_transcription_frappe_done(request) {
    return {
        type: 'VALIDE_TRANSCRIPTION_FRAPPE_DONE',
        payload: request,
    };
}

export function relache_frappe(payload) {
    return {
        type: 'RELACHE_FRAPPE',
        payload,
    };
}
export function relache_frappe_done(request) {
    return {
        type: 'RELACHE_FRAPPE_DONE',
        payload: request,
    };
}

export function finalisation_frappe(payload) {
    return {
        type: 'FINALISATION_FRAPPE',
        payload,
    };
}
export function finalisation_frappe_done(request) {
    return {
        type: 'FINALISATION_FRAPPE_DONE',
        payload: request,
    };
}

export function check_changement(payload) {
    return {
        type: 'CHECK_CHANGEMENT',
        payload,
    };
}
export function check_changement_done(request) {
    return {
        type: 'CHECK_CHANGEMENT_DONE',
        payload: request,
    };
}


export function check_user_has_frappe(payload) {
    return {
        type: 'CHECK_USER_HAS_FRAPPE',
        payload,
    };
}
export function check_user_has_frappe_done(request) {
    return {
        type: 'CHECK_USER_HAS_FRAPPE_DONE',
        payload: request,
    };
}

