import { takeEvery, call } from 'redux-saga/effects';
import { callApi } from '../services/Callapi';
import { get, post } from '../services/request';
import { apiUrl, url_changePassword, url_changePasswordConfirm, url_login, url_refresh_token, url_user_info} from '../services/api';
import { change_password_action_done, confirm_password_action_done, get_user_action_done, login_action_done, refresh_token_action_done } from '../actions/UserAction';

function* get_user_action_saga() {
	yield call(
		callApi,
		apiUrl + url_user_info,
		get,
        null,
        get_user_action_done,
	);
}
export function* watch_get_user_action_saga() {
    yield takeEvery('USER_INFO', get_user_action_saga);
}

function* login_saga(action) {
	yield call(
		callApi,
		apiUrl + url_login,
		post,
        action.payload,
        login_action_done,
	);
}
export function* watch_login_saga() {
    yield takeEvery('LOGIN', login_saga);
}

function* change_password_saga(action) {
	yield call(
		callApi,
		apiUrl + url_changePassword,
		post,
        action.payload,
        change_password_action_done,
	);
}
export function* watch_change_password_saga() {
    yield takeEvery('CHANGE_PASSWORD', change_password_saga);
}

function* confirm_password_saga(action) {
	yield call(
		callApi,
		apiUrl + url_changePasswordConfirm,
		post,
        action.payload,
        confirm_password_action_done,
	);
}
export function* watch_confirm_password_saga() {
    yield takeEvery('CONFIRM_PASSWORD', confirm_password_saga);
}

function* refresh_token_saga(action) {
	yield call(
		callApi,
		apiUrl + url_refresh_token,
		post,
        action.payload,
        refresh_token_action_done,
	);
}
export function* watch_refresh_token_saga() {
    yield takeEvery('REFRESH_TOKEN', refresh_token_saga);
}
