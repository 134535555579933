// eslint-disable-next-line import/no-anonymous-default-export
export default {
    Redirection : '/',
    login :'/login', 
    client : '/client/home',
    clientAddTranscription : '/client/nouvelle-demande',
    clientDetailTranscription : '/client/detail-demande',
    clientModifierTranscription : '/client/modifier-demande',
    utilisateur : '/utilisateur/home',
    utilisateurDetailFrappe : '/utilisateur/detail-frappe',
    utilisateurDetailRelecture : '/utilisateur/detail-relecture',
    admin : '/admin/home',
};