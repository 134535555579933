
export const apiUrl = process.env.REACT_APP_API_URL;
//export const admin_location = process.env.REACT_APP_ADMIN_LOCATION;
export const admin_location = '2dfbc184-89d4-4bac-b99e-c2977c986464';
export const url_user_info = 'api/account/info-profile/';
export const url_login = 'api/account/login/';
export const url_refresh_token = 'api/account/refresh-token/';
export const url_changePassword = 'api/password_reset/';
export const url_changePasswordConfirm = 'api/password_reset/confirm/';

export const url_add_transcription = 'api/core/add-demande-transcription/';
export const url_edit_transcription = 'api/core/modify-demande-transcription/';
export const url_get_transcriptions = 'api/core/list-demandes/';
export const url_detail_transcriptions = 'api/core/detail-demande-transcription/';
export const url_download_transcription_frappe = 'api/core/download-transcription/';

export const url_get_liste_frappe = 'api/core/frappes/';
export const url_get_liste_relecture = 'api/core/frappes/';
export const url_detail_frappe = 'api/core/frappes/';
export const url_edit_frappe = 'api/core/frappes/';
export const url_edit_relecture = 'api/core/frappes/';
export const url_add_transcription_frappe = 'api/core/transcription/';
export const url_edit_transcription_frappe = 'api/core/transcription/';
export const url_delete_transcription_frappe = 'api/core/transcription/';
export const url_valider_transcription_frappe = 'api/core/transcription/';
export const url_relache_frappe = 'api/core/relache-frappe/';
export const url_check_changement_frappe = 'api/core/need-to-comment/';
export const url_finalisation_frappe = 'api/core/finalize-frappe/';
export const url_check_user_has_frappe = 'api/core/need-to-relache/';

export const url_get_list_filters = 'api/core/list-filter-reporting/'
export const url_get_list_related_filters = 'api/core/list-related-filter-reporting/'
export const url_get_reports = 'api/core/reporting/'

