

export function get_user_action(payload) {
    return {
        type: 'USER_INFO',
        payload,
    };
}
export function get_user_action_done(request) {
    return {
        type: 'USER_INFO_DONE',
        payload: request,
    };
}


export function login_action(payload) {
    return {
        type: 'LOGIN',
        payload,
    };
}
export function login_action_done(request) {
    return {
        type: 'LOGIN_DONE',
        payload: request,
    };
}


export function change_password_action(payload) {
    return {
        type: 'CHANGE_PASSWORD',
        payload,
    };
}
export function change_password_action_done(request) {
    return {
        type: 'CHANGE_PASSWORD_DONE',
        payload: request,
    };
}


export function confirm_password_action(payload) {
    return {
        type: 'CONFIRM_PASSWORD',
        payload,
    };
}
export function confirm_password_action_done(request) {
    return {
        type: 'CONFIRM_PASSWORD_DONE',
        payload: request,
    };
}


export function refresh_token_action(payload) {
    return {
        type: 'REFRESH_TOKEN',
        payload,
    };
}
export function refresh_token_action_done(request) {
    return {
        type: 'REFRESH_TOKEN_DONE',
        payload: request,
    };
}