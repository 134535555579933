

export function get_liste_transcriptions(payload) {
    return {
        type: 'LISTE_TRANSCRIPTIONS',
        payload,
    };
}
export function get_liste_transcriptions_done(request) {
    return {
        type: 'LISTE_TRANSCRIPTIONS_DONE',
        payload: request,
    };
}


export function add_transcription(payload) {
    return {
        type: 'ADD_TRANSCRIPTION',
        payload,
    };
}
export function add_transcription_done(request) {
    return {
        type: 'ADD_TRANSCRIPTION_DONE',
        payload: request,
    };
}


export function edit_transcription(payload) {
    return {
        type: 'EDIT_TRANSCRIPTION',
        payload,
    };
}
export function edit_transcription_done(request) {
    return {
        type: 'EDIT_TRANSCRIPTION_DONE',
        payload: request,
    };
}


export function Detail_transcription(payload) {
    return {
        type: 'DETAIL_TRANSCRIPTION',
        payload,
    };
}
export function Detail_transcription_done(request) {
    return {
        type: 'DETAIL_TRANSCRIPTION_DONE',
        payload: request,
    };
}

export function delete_transcription(payload) {
    return {
        type: 'DELETE_TRANSCRIPTION',
        payload,
    };
}
export function delete_transcription_done(request) {
    return {
        type: 'DELETE_TRANSCRIPTION_DONE',
        payload: request,
    };
}

export function download_transcription_frappe(payload) {
    return {
        type: 'DOWNLOAD_TRANSCRIPTION',
        payload,
    };
}
export function download_transcription_frappe_done(request) {
    return {
        type: 'DOWNLOAD_TRANSCRIPTION_DONE',
        payload: request,
    };
}