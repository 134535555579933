export const initialState = {
    liste_transcrption : [],
    result : {},
    edited_transcription : {},
    delete : {},
    transcription : {},
    download : {},
}

const transcription = (state = initialState, action) => {
    switch (action.type) {
        case 'LISTE_TRANSCRIPTIONS_DONE':
            return {
                ...state,
                liste_transcrption : action.payload
            }
        case 'ADD_TRANSCRIPTION_DONE':
            return {
                ...state,
                result: action.payload,
            }
        case 'EDIT_TRANSCRIPTION_DONE':
            return {
                ...state,
                edited_transcription: action.payload,
            }
        case 'DETAIL_TRANSCRIPTION_DONE':
            return {
                ...state,
                transcription: action.payload,
            }
        case 'DELETE_TRANSCRIPTION_DONE':
            return {
                ...state,
                delete: action.payload,
            }
        case 'DOWNLOAD_TRANSCRIPTION':
            return {
                ...state,
                download: action.payload,
            }
        default:
            return state;
    }
}
export default transcription