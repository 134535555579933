export const initialState = {
    userInfo : {},
    login : {},
    passwordChanged : {},
    passwordConfirmed : {},
    refresh_token : {},
}

const user = (state = {}, action) => {
    switch (action.type) {
        case 'LOGIN_DONE':
            return {
                ...state,
                login: action.payload,
            }
        case 'CHANGE_PASSWORD_DONE':
            return {
                ...state,
                passwordChanged: action.payload,
            }
        case 'CONFIRM_PASSWORD_DONE':
            return {
                ...state,
                passwordConfirmed: action.payload,
            }
        case 'USER_INFO_DONE':
            return {
                ...state,
                userInfo: action.payload,
            }
        case 'REFRESH_TOKEN_DONE':
            return {
                ...state,
                refresh_token: action.payload,
            }
        default:
            return state;
    }
}
export default user